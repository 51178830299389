<template>
  <div class="favorite-game">
    <div class="favorite-game__list">
      <GameList />
    </div>
  </div>
</template>
<script setup lang="ts">
const GameList = defineAsyncComponent(() => import('~/components/desktop/pages/home/game-center/list.vue'))
</script>
<style scoped lang="scss" src="assets/scss/components/desktop/pages/home/game-center/index.scss"></style>
